import React from 'react';
import settings from '../../settings';
import Sprite from './icon_sprite.png';

export default function({sizePx,opacity}) {

	return (
	<div style={{
		height: sizePx+'px',
		whiteSpace: 'nowrap',
	}}>
		<SocialIcon platform="facebook" sizePx={sizePx} opacity={opacity} href={settings.social.facebook.url} />
		<SocialIcon platform="instagram" sizePx={sizePx} opacity={opacity} href={settings.social.instagram.url} />
		<SocialIcon platform="linkedin" sizePx={sizePx} opacity={opacity} href={settings.social.linkedin.url} />
		<SocialIcon platform="google" sizePx={sizePx} opacity={opacity} href={settings.social.google.url} />
	</div>
	);
}

function SocialIcon({platform,sizePx,opacity,href}){

	const icon_coordinates	=	{
		facebook:	['0px 0px'],
		instagram:	['0px -55px'],
		linkedin:	['-53px -55px'],
		google:		['-159px 0px'],
		twitter:	['-106px -110px'],
		youtube:	['-106px 0px'],
		pinterest:	['-159px -110px'],
	}

	const coordinates	=	icon_coordinates[platform].join(' ');

	return (
		<a href={href} target="_blank" rel="noreferrer">
			<div
				title={platform}
				style={{
					backgroundImage: "url('"+Sprite+"')",
					backgroundPosition: coordinates,
					backgroundRepeat: 'no-repeat',
					backgroundSize: '200px',
					cursor: 'pointer',
					display: 'inline-block',
					opacity: opacity || '0.3',
					height:	'100%',
					width: sizePx+'px',
				}}
			></div>
		</a>
	);
}