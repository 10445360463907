import React, {useRef} from 'react';
import { Link } from 'gatsby';
import {
	MenuItem,
} from 'react-pro-sidebar';

//	Component
export default function MenuItemWrapper({children,to,handleHashChange,style}){

//	-------------------------------
//	For Detecting if link is Active
//	-------------------------------
	const theRef					=	useRef();

//	-----------------------------------------
//	For Handling onclick of link / Url Change
//	-----------------------------------------
	const properties	=	{};
	if( handleHashChange ){
		properties.onClick = function(){
		//	console.log(to,theRef.current.hash);
			handleHashChange(theRef.current);
		};
	}

//	-----------
//	HTML Output
//	-----------
	return (
		<MenuItem active={isLinkActive(to)} style={style} >
			<Link to={to} {...properties} ref={theRef} >{children}</Link>
		</MenuItem>
	);
}

//	-------------------
//	Support Function(s)
//	-------------------
//	Not only called after initial state change so no clever checking for 'window' object needed.
	function isLinkActive(href){
		if( typeof window == 'undefined' )	return false;
	//	console.log(window.location.href,href,window.location.href.indexOf(href));
		if( window.location.href.indexOf(href) === -1 )	return false;
		return true;
	}