import React from 'react';
import { Link } from 'gatsby';
//import { window } from 'browser-monads';
import SocialMediaLinks from '../SocialMediaLinks';
import {
	ProSidebar,
	SidebarHeader,
	SidebarFooter,
	SidebarContent,
} from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import background_image from '../Navigation/background_menu.jpg';
import MenuWrapper from './MenuWrapper';

//	https://reactjsexample.com/customizable-and-responsive-react-sidebar-library-with-dropdown-menus/

export default function({toggled,handleToggleSidebar}){

	const year = (new Date()).getFullYear();

	return (
		<ProSidebar
			image={background_image}
			breakPoint="md"
			toggled={toggled}
			onToggle={handleToggleSidebar}
		>
			<SidebarHeader>
				<div
					style={{
						color:'black',
						fontWeight: 'bold',
						fontSize: '30px',
						letterSpacing: '1px',
						overflow: 'hidden',
						padding: '24px',
						textAlign: 'center',
						textOverflow: 'ellipsis',
						textShadow: '0 0 3px SlateGrey',
						whiteSpace: 'nowrap',
					}}
					>
					<Link to="/" style={{color:'black',textDecoration:'none'}}>
						Blackstone
						<span style={{
							color: 'ForestGreen',
							fontSize: '9px',
							verticalAlign: 'top',
						}}
						>VT</span>
					</Link>
				</div>
			</SidebarHeader>
			<SidebarContent>
				<MenuWrapper/>
			</SidebarContent>
			<SidebarFooter style={{ textAlign: 'center' }}>
				<div
					className=""
					style={{
						padding: '20px 24px'
					}}
				>
				<SocialMediaLinks sizePx="40" opacity="0.6" />
				<hr/>
				&copy; {year}
				</div>
			</SidebarFooter>
		</ProSidebar>
	);
}