import React from 'react';
//	import settings from '../../settings';
import {Link} from 'gatsby';

import '../../styles/components/BadgeContactUs.scss';

export default function(){

	return (
		<div className="badge-contact_us">
			<Link to="/contact/">
				<div className="link_wrapper">
					Request<br/>Quote <span role="img" aria-label="Envelope Icon">📨</span>
				</div>
			</Link>
		</div>
	);

}
