import React from 'react';
import {Link} from 'gatsby';
import SocialMediaLinks from "../SocialMediaLinks";
import settings from '../../settings';

export default function() {
  return (
	<div	className="py-5 py-md-6"
			style={{
				backgroundColor: "#1e1e1e",
				borderTop: '1px solid #414042',
				position: 'relative',
				width: '100%',
			}}
	>
		<div className="container" style={{height:'100%'}}>
			<div className="row align-items-center text-center" style={{height:'100%'}}>
				<div className="col-sm-12 col-md mb-5 mb-md-0" style={{
					color: '#ffcd00',
					fontSize: '28px',
					fontWeight: '900',
					lineHeight: '21px',
				}}>
					<div style={{display:'inline-block',textAlign:'left'}}>
						<div>BLAC</div>
						<div>KSTO</div>
						<div>NE<span style={{color:settings.color.bg.dark,textShadow: '0px 0px 22px ForestGreen'}}>VT</span></div>
					</div>
				</div>
				<div className="col-sm-12 col-md mb-5 mb-md-0">
					<Link to="/contact" style={{
						color: settings.color.font.light,
						fontSize: '16px',
						fontWeight: '100',
					}}>Request Quote <span role="img" aria-label="Envelope Icon">📨</span></Link>
				</div>
				<div className="col-sm-12 col-md">
					<SocialMediaLinks sizePx="40"/>
				</div>
			</div>
		</div>
	</div>
	);
}
