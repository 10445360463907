import settings from '../../settings';

	const address_PostalAddress	=	{
			'@type'			:	'PostalAddress',
//			streetAddress	:	'148 W 51st St',
			addressLocality	:	'Chester',
			addressRegion	:	'VT',
			postalCode		:	'05143',
			addressCountry	:	'US',
	};

	const JSONLD	=	{
		'@context'	:	'https://schema.org',
		'@type'		:	'HomeAndConstructionBusiness',
		'@id'		:	settings.host.url,
		areaServed	:	{
			'@type'	:	'AdministrativeArea',
			'name'	:	'Vermont',
		},
		address		:	address_PostalAddress,
		contactPoint:	[
			{
				'@type'		:	'ContactPoint',
				contactType	:	'Customer Service',
				url			:	[settings.host.url,'contact/'].join('/'),
    		},
    	],
		currenciesAccepted			:	'USD',
		disambiguatingDescription	:	'Blackstone VT Landscaping',
		founder						:	{
			'@type'	:	'Person',
			jobTitle:	'Owner',
			name	:	 'James Cole',
		},
		foundingDate	:	'2017-07-24',
		foundingLocation:	{
			'@type'	:	'Place',
			address	:	address_PostalAddress,
		},
		geo	:	{
			'@type'		:	'GeoCoordinates',
			latitude	:	43.2870495,
			longitude	:	-72.6856408,
		},//geo
		image		:	[
			[settings.host.url,'logo.png'].join('/')
		],//image
		knowsAbout		:	[
			'Landscaping',
			'Hardscapes',
			'Stonework',
			'Mowing',
			'Stone Veneer',
		],
		knowsLanguage	:	{
			'@type'			:	'Language',
			name			:	'English',
			alternateName	:	'en',
		},
		legalName		:	'Blackstone LLC',
		location		:	{
			'@type'			:	'PostalAddress',
//			streetAddress	:	'148 W 51st St',
			addressLocality	:	'Chester',
			addressRegion	:	'VT',
			postalCode		:	'05143',
			addressCountry	:	'US',
		},//address
//		logo		:	'URL',
		name		:	'Blackstone',
		openingHoursSpecification	:	[
			{
				'@type'	:	'OpeningHoursSpecification',
				dayOfWeek	:	[
					'Monday',
					'Tuesday',
					'Wednesday',
					'Thursday',
					'Friday',
				],
				opens	:	'07:00',
				closes	:	'17:00',
			},
			{
				'@type'		:	'OpeningHoursSpecification',
				dayOfWeek	:	[
					'Saturday',
				],
				opens	:	'09:00',
				closes	:	'17:00',
			},
		],//openingHoursSpecification
		paymentAccepted		:	'Cash, Credit Card, PayPal, Venmo, Zelle',
		priceRange			:	'$$$',
		numberOfEmployees	:	'2 - 10',
	//	telephone			:	'+12122459600', 
		url					:	settings.host.url,
		slogan				:	'Building authentic and iconic stone masonry to stand against time.',
	};

export default JSON.stringify(JSONLD);