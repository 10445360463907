import React, {useRef} from 'react';
//	import { navigate } from '@reach/router';

import scrollTo from 'gatsby-plugin-smoothscroll';
import SmartMenuItem from '../MenuItemWrapper';
import {
	Menu,
} from 'react-pro-sidebar';
import SubMenuWrapper from '../SubMenuWrapper';

//	https://reactjsexample.com/customizable-and-responsive-react-sidebar-library-with-dropdown-menus/

export default function(){

	//	--------------------------
	//	To manage Anchor tag links
	//	FYI:	The normal <Link> action will navigate before invoking the function attached to the onClick property
	//			However the <Link> component doesn't support navigating to hash values so we have to do it manually.
	//			If the <Link> action only differs from the current location by the location's .hash value, then no true navigation will occur although the url hash does change.
	//			Therefore, we attach this function on the Link's onClick property... so if the <Link> does not navigate, we'll navigate it here.
	//	--------------------------
	const refHardscapes		=	useRef(null);
	const refLandscapes		=	useRef(null);
	const refPlanting		=	useRef(null);

	const handleHashChange = function(anchor_element){
	//	Yes, you can use an <a> element's properties like a Location object.
	
		//	-----------------------------------
		//	- Deflag any existing links as active
		//	- Flag true active link
		//	-----------------------------------
		[].forEach.call(document.querySelectorAll('.pro-menu-item a'),function(_a){
			const parent	=	_a.parentElement.parentElement.parentElement;
			if( _a === anchor_element ){
				parent.classList.add('active')
			}else{
				parent.classList.remove('active')
			}
		});


/*
		console.log(
			selectedUrl,
			window.location.pathname,
			anchor_element.pathname,
			window.location.hash,
			anchor_element.hash,
			window.location.href,
			anchor_element.href,
//			url_path,
			window.location.pathname != anchor_element.pathname,
		);	//	Debugging Only
*/
	//	----------------
	//	Early Breakpoint
	//	----------------
		//	- If the window is going to navigate to a new page anyway... none of this methods hash handling needs to take place.
		if( window.location.pathname !== anchor_element.pathname)	return false;

	//	---------
	//	Scrolling
	//	---------
		//	Do native browser scrolling to element with hash value
	//	window.location.hash = 		anchor_element.hash;

		//	Synthesize navigation to the Location.hash parameter and scroll smoothly
	//	navigate(anchor_element.hash);
		scrollTo(anchor_element.hash);

	//	------------------------
	//	Update State of Children
	//	------------------------
		const url_path	=	`${anchor_element.pathname}${anchor_element.hash}`;
		const newState	=	{selectedUrl:url_path};

		refHardscapes.current.setState(newState);
		refLandscapes.current.setState(newState);
		refPlanting.current.setState(newState);

	};

	return (
	<Menu iconShape="square">
		<SubMenuWrapper title="Hardscapes" urlPath="/hardscapes" defaultOpen={false} ref={refHardscapes} >
			<SmartMenuItem to="/hardscapes/#walls" handleHashChange={handleHashChange}>Walls</SmartMenuItem>
			<SmartMenuItem to="/hardscapes/#patios-n-walkways" handleHashChange={handleHashChange}>Patios / Walkways</SmartMenuItem>
			<SmartMenuItem to="/hardscapes/#before-n-after" handleHashChange={handleHashChange}>Before / After</SmartMenuItem>
		</SubMenuWrapper>
		<SubMenuWrapper title="Landscaping" urlPath="/landscaping" defaultOpen={false} ref={refLandscapes} >
			<SmartMenuItem to="/landscaping/#mowing" handleHashChange={handleHashChange}>Mowing</SmartMenuItem>
			<SmartMenuItem to="/landscaping/#drainage" handleHashChange={handleHashChange}>Drainage</SmartMenuItem>
			<SmartMenuItem to="/landscaping/#grading" handleHashChange={handleHashChange}>Grade Leveling</SmartMenuItem>
		</SubMenuWrapper>
		<SubMenuWrapper title="Planting" urlPath="/planting" defaultOpen={false} ref={refPlanting} >
			<SmartMenuItem to="/planting/#trees" handleHashChange={handleHashChange}>Trees</SmartMenuItem>
			<SmartMenuItem to="/planting/#shrubs" handleHashChange={handleHashChange}>Shrubs</SmartMenuItem>
			<SmartMenuItem to="/planting/#perennials" handleHashChange={handleHashChange}>Perennials</SmartMenuItem>
			<SmartMenuItem to="/planting/#baskets" handleHashChange={handleHashChange}>Baskets</SmartMenuItem>
			<SmartMenuItem to="/planting/#potting" handleHashChange={handleHashChange}>Potting</SmartMenuItem>
		</SubMenuWrapper>
		<SmartMenuItem to="/veneer/">Veneer</SmartMenuItem>
		<SmartMenuItem to="/winter/">Winter Services</SmartMenuItem>
		<hr/>
		<SmartMenuItem to="/contact/" style={{letterSpacing:'2px'}}>Request Quote <span role="img" aria-label="Envelope Icon">📨</span></SmartMenuItem>
		<SmartMenuItem to="/careers/">Careers</SmartMenuItem>
		<SmartMenuItem to="/about/">About Us</SmartMenuItem>
	</Menu>

	);
}