import React, {useState,
//		useRef,
//		createContext,
} from 'react';
import Helmet from 'react-helmet';
import settings from '../../settings';
import Navigation from '../Navigation';
import HamburgerButton from '../HamburgerButton';
import Footer from '../Footer';
import BadgeContactUs from '../BadgeContactUs';
import JSONLD from '../StructuredData'

function Layout({children,className}) {

	const [toggled, setToggled]	=	useState(false);

	const handleToggleSidebar = function(value){
		setToggled(value);
	};

	return (
	<div className={`app ${toggled?'menu_showing':''}`}>
		<Helmet>
				<meta charSet="utf-8" />
				<title>Blackstone VT • Stone Masonry, Landscaping & Hardscaping</title>
				<link rel="canonical" href={settings.host.url} />
				<meta
					name="description"
					content="Blackstone provides quality hardscape & landscape development as well as mowing, planting, and property maintenance services within Southern Vermont."
				/>
				<script type="application/ld+json">{JSONLD}</script>
		</Helmet>
		<Navigation
			toggled={toggled}
			handleToggleSidebar={handleToggleSidebar}
		/>
		<main>
			<HamburgerButton
				handleToggleSidebar={handleToggleSidebar}
			/>
			{children}
			<Footer/>
		</main>
		<BadgeContactUs/>
	</div>
	);
}

export default Layout;