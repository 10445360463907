import React from 'react';
import {
	SubMenu,
} from 'react-pro-sidebar';


//	https://reactjsexample.com/customizable-and-responsive-react-sidebar-library-with-dropdown-menus/

export default class SubMenuWrapper extends React.Component {

	constructor(props){
		super(props);
		const {children,title,urlPath}	=	props;
		this.state	=	{
			selectedUrl:	'/',
		};
		this.title		=	title;
		this.urlPath	=	urlPath;
		this.children	=	children;
	}

	//	Function
	//	Called during rendering - so we need clever checking for presence of authentic 'window' object.
	shouldBeOpen(){
		if( !this.urlPath || typeof window == 'undefined' )	return;	//	Early Breakpoint - To prevent the initial statically rendered state from being 'active' for all links because there's no window.
	//	console.log('Marker 1',window.location.href,href,window.location.href.indexOf(href));
		if( window.location.href.indexOf(this.urlPath) === -1 )	return;
		return true;
	}

	render(){
		return (
			<SubMenu title={this.title} defaultOpen={false} open={this.shouldBeOpen()} >{this.children}</SubMenu>
		);
	}
}
